export default {
  smartUnlock: "Smart Unlock",
  helpCenter: "Help Center",
  commonProblems: "Common Issues",
  popularFeature: "Popular Features",
  desktopWidgets: "Desktop Shortcut Widgets",
  serviceTime: "Daily 09:00-21:00",
  servicePhone: "Customer Service",
  resolved: "Resolved",
  unresolved: " Unresolved",
  widgetFeature: "Widget Feature Launched",
  iosSystemUpgrade: "The system needs to be upgraded to iOS 14 or later",
  useIosStepOne: "Long press on the home screen.",
  useIosStepTwo: `Tap "+" icon in the top left corner.`,
  useIosStepThree: `Select the "Chengqu" widget and add it to the home screen.`,
  shortVehicleOffline: "Vehicle Not Online",
  shortVehicleOfflineQ:
    'The network is normal, but the app shows "Vehicle Not Online".',
  shortSmartUnlock: "Smart Lock/Unlock Function Not Senstive",
  shortSmartUnlockQ:
    "Vehicle neither automatically unlock when approaching, nor automatically lock when leaving.",
  shortControlBle: "Bluetooth Connection Failure",
  shortControlBleQ:
    'When approaching the vehicle and opening the digital key, the app shows "Bluetooth Not Connected".',
  shortVehicleControl: "Vehicle Control Function Executed Failure",
  shortVehicleControlQ:
    "Vehicle control function executed failure while using digital key.",
  shortFastPower: 'Unable to Use "One-Key Start"',
  shortFastPowerQ:
    'After getting into the vehicle and pressing the "One-Key Start" button to start the vehicle, the dashboard displays "Key Not Detected".',
  shortOta: "OTA Upgrade Failure",
  shortOtaQ:
    "When upgrading the firmware, the app shows upgraded failure or timeout.",
  problemDescription: "Problem Description:",
  vehicleOffline:
    'Phone network is normal, but the app shows "Vehicle Not Online".',
  solution: "Solution:",
  offlineMethodOne:
    ' The vehicle uses an IoT network, and some areas may have weak IoT signal coverage, which could limit the vehicle’s connectivity. It is recommended to drive to another area and try again. If the app continuously shows "Vehicle Not Online" regardless of location, please go to the digital key module and click "Restart Digital Key". Please check whether the connection is restored after restarting.',
  offlineMethodTwo:
    " If the VIP membership expired, the 4G functionality will be disabled. Please renew your membership, and the 4G functionality will be restored after renewal.",
  offlineMethodThree:
    ' If the above solutions do not resolve the issue, please move closer to the vehicle, establish a Bluetooth connection, then go to Vehicle Settings -> Vehicle Network Signal Diagnosis. Click "Start Diagnosis" and contact customer service for assistance once completed.',
  smartUnlockIssue: " Unresponsive Smart Lock",
  smartUnlockPhenomenon:
    "The touch sensor on the door handle and smart lock/unlock function are not sensitive.",
  smartUnlockMethodOne:
    "Some phones may not be able to automatically connect to Bluetooth all of the time. This is similar to using Bluetooth headphones, where sometimes the connection doesn't establish automatically. If you encounter this issue, please open the app and manually tap to unlock.",
  smartUnlockMethodTwo:
    "Adjust the sensitivity based on the guidance in the app. It is recommended to repeat serval times.",
  smartUnlockMethodThree:
    "Bluetooth signal is weak near the tailgate, causing the digital key to mistakenly think the user has moved away from the vehicle. This issue can be somehow avoided by increasing the lock distance.",
  smartUnlockMethodFour:
    "Smart lock function also relies on Bluetooth connection. If Bluetooth is not connected when leaving the vehicle, the vehicle will not trigger the locking mechanism. In this case, please open the app and manually lock the vehicle.",
  smartUnlockMethodFive:
    "If the above steps do not resolve the issue, please contact customer service for assistance.",
  bluetoothNotConnecting: "Bluetooth Connection Failure",
  bluetoothNotConnectingPhoenomenon:
    'When near the vehicle, the Bluetooth icon in the digital key module page appears gray. Upon entering the digital key module, message "Bluetooth not connected" occured on the top.',
  bluetoothNotConnectingMethodOne:
    "Bluetooth can only connect when near the vehicle; it cannot connect when you are not close to the vehicle.",
  bluetoothNotConnectingMethodTwo:
    "If the app prompts for Bluetooth repair, please follow the instructions to complete the repair.",
  bluetoothNotConnectingMethodThree:
    'If you are near the vehicle but the app still shows "Bluetooth not connected", you can try the following three methods: ',
  bluetoothNotConnectingMethodThreeOne:
    'a. Go to the system Bluetooth settings, find the device starting with "ikey", and click "Unpair". Then, open the app and wait for the pairing prompt, click to pair and connect.',
  bluetoothNotConnectingMethodThreeTwo:
    "b. Go to system Bluetooth settings and turn Bluetooth switch off and on. ",
  bluetoothNotConnectingMethodThreeThree: " c. Restart your phone.",
  bluetoothNotConnectingMethodFour:
    "If none of the above methods resolve the issue, please contact customer service for assistance.",
  controlExecutionFailed: "Vehicle Control Execution Failure",
  controlExecutionFailedPhoenomenon:
    "When clicking on the vehicle control button in the digital key, the vehicle does not execute the command successfully.",
  controlExecutionFailedMethodOne:
    "If you click the vehicle control button, and after the loading is complete, the vehicle does not respond and the app does not show any message, it means the control command was sent successfully, but the vehicle did not execute it. Occasional execution failures are normal, and we recommend trying again a few times.",
  controlExecutionFailedMethodTwo:
    'If you click the vehicle control button remotely, and after the loading is complete, the app displays the message "No response from vehicle system", this is usually caused by a signal issue. We recommend trying again or getting closer to the vehicle and using Bluetooth to control the vehicle.',
  controlExecutionFailedMethodThree:
    'If you are near the vehicle and click the vehicle control button, and after the loading is complete, the app displays "No response from vehicle system", please contact customer service for assistance.',
  oneClickStartFailed: "Unable to Use 'One-Key Start'",
  oneClickStartNoKeyDetectedPhoenomenon:
    'After getting into the vehicle and using the "One-Key Start" button to start the vehicle, the dashboard displays "Key Not Detected".',
  oneClickStartNoKeyDetectedMethodOne:
    'For certain vehicle models, in normal mode, as long as the vehicle is unlocked with the digital key, you can start the vehicle with the "One-Key Start" button. If the dashboard shows "Key Not Detected", it is likely that the vehicle has been locked or unlocked using another method, or the lock was triggered. In this case, simply open the app, manually unlock the vehicle, and then try starting the vehicle again.',
  oneClickStartNoKeyDetectedMethodTwo:
    'If you click the vehicle control button remotely, and after the loading is complete, the app displays the message "No response from vehicle system", this is usually caused by a signal issue. We recommend trying again or getting closer to the vehicle and using Bluetooth to control the vehicle.',
  oneClickStartNoKeyDetectedMethodThree:
    "If none of the above steps resolve the issue, please contact customer service for assistance.",
  otaUpgradeFailed: "OTA Upgrade Failure",
  otaUpgradeFailedPhoenomenon:
    'When an upgrade is pushed, entering the digital key module -> OTA - Firmware Upgrade -> Clicking "Upgrade to Latest Version" results in an upgrade failure message.',
  otaUpgradeFailedMethodOne:
    "Ensure the vehicle is stationary, and click the upgrade button multiple times. Try several times.",
  otaUpgradeFailedMethodtwo:
    "If the vehicle is in an area with weak signal, remote upgrading may fail. It is recommended to move closer to the vehicle and wait for the Bluetooth connection to establish before attempting the upgrade again.",
  otaUpgradeFailedMethodThree:
    'Enter the digital key module, click "Restart Digital Key", then try the upgrade again after the restart.',
  otaUpgradeFailedMethodFour:
    "If the upgrade still fails after these steps, please contact customer service for assistance.",
  widgetFeatureOnline: "Widget Feature Launched",
  longPressHomeScreen: ".Long press on the home screen.",
  tapPlusIcon: 'Tap "+" icon in the top left corner.',
  selectRideFunWidget:
    'Select the "Chengqu" widget and add it to the home screen.',
  iosVersionRequirement:
    "System needs to be upgraded to iOS 14 or later version",
  tapAddWidget: "Tap 'Add Widget/Window Tool'",
  addRideFunWidget:
    'Select the "Chengqu" widget and add it to the home screen.',
  welcomeSmartUnlock: "Welcome to Smart Unlock",
  smartUnlockDescription:
    "Smart Unlock is an innovative experimental feature and may have some instability. ",
  smartUnlockDescriptionTwo:
    "Please read carefully before deciding whether to enable it.",

  aboutConnection: "About Connection",
  approachVehicle:
    "Approach the vehicle, and your phone will automatically establish a Bluetooth connection with the car. The vehicle determines the distance between your phone and the car via Bluetooth signals. <strong>Once you enter the unlock zone, the car will automatically unlock; moving away will lock it automatically.</strong>",
  unableToAutoUnlock: "What if approaching the vehicle doesn’t auto-unlock?",
  connectionIssueExplanation:
    "Some phones cannot always connect successfully, similar to occasional connection issues with Bluetooth earphones. If automatic connection fails, Smart Unlock cannot be triggered. <strong>Please open the app and select manual unlock.</strong>",
  aboutSensitivity: "About Sensitivity",
  adjustSensitivityExplanation:
    "If you experience unlocking delays, such as having to wait a few seconds after approaching the car, <strong>you can adjust the sensitivity to achieve the best unlocking experience.</strong>",
};
